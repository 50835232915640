import {invert} from 'lodash';
import en from './locales/en.json';
import fr from './locales/fr.json';

export const VUE_APP_URL = process.env.VUE_APP_URL;
export const VUE_APP_MEDIA_URL = process.env.VUE_APP_MEDIA_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const VUE_APP_CHANNEL_NAME = process.env.VUE_APP_CHANNEL_CONFIG_NAME ||
    document.location.host.split('.')[0];
export const CHANNEL_KEY = process.env.VUE_APP_CHANNEL_KEY;

const schema = 'https:';
export const API = {
  API_HOST: `${schema}//${VUE_APP_URL}/api`,
  API_KEYS_HOST: `${schema}//${VUE_APP_URL}/storage/settings/`,
  MEDIA_HOST: `${schema}//${VUE_APP_MEDIA_URL}`,
};

export const LANGUAGES = {
  en,
  fr,
};
export const DEFAULT_LANGUAGE = 'en';

export const MEDIA_TYPE = {
  image: 1,
  live: 2,
  vod: 3,
  embedded: 4,
  thumbnails: 5,
  preview: 6,
  preview_alt: 7,
  embedded_preview: 8,
};

export const ASSETS_TYPE = {
  preview: 'preview',
  image: 'image',
  video: 'video',
  embed: 'embed',
};

export const URL_QUERIES = {
  EVENT_ID: 'event_id',
  CATEGORY: 'category_id',
  participantGroup: 'school',
  category: 'sport',
  CHANNEL: 'channel',
  DIVISION: 'division',
  GROUP: 'group',
  MODAL: 'modal',
  EVENT_CATEGORY_ID: 'event_category_id',
  PARTICIPANT_ID: 'participant_id',
  SINGLE_PACKAGE_ID: 'single_event_package_id',
  DEVICE_CODE: 'code',
  V3_OUTER_TRACKING: 'trk',
  PACKAGE_ID: 'package_id',
};

export const ALL_CATEGORY = {
  title: 'All',
  id: 0,
  parent_id: 0,
};

export const MEDIA_TYPE_NAME_BY_ID = invert(MEDIA_TYPE);

export const MENU_POSITION = {
  header: 'header',
  footer: 'footer',
  both: 'both',
};

export const STREAM_STATUS = {
  vod: 'finished',
  live: 'live',
  upcoming: 'upcoming',
};

export const EVENT_TYPE = {
  free: 'free',
  paid: 'paid',
  register: 'register',
  ppv: 'ppv',
};

export const ORDER_STATUS_TEXT = {
  paid: 'Paid',
  inactive: 'Inactive',
  refunded: 'Refunded',
  active: 'Active',
};

export const TRANSACTION_STATUS = {
  created: 'New subscription',
  updated: 'Subscription Renewal',
};
export const RENEW_STATUS = {
  cancelled: 'Auto-Renewal Cancelled',
  active: 'Auto-Renewal',
};

export const ORDER_STATUS = {
  created: 'created',
  failed: 'failed',
  paid: 'paid',
  refunded: 'refunded',
};
export const ORDER_ACTIVITY_STATUS = {
  expired: 'expired',
  inactive: 'inactive',
  active: 'active',
  canceled: 'canceled',
};
export const ORDER_TYPE = {
  subscription: 'subscription',
  package: 'package',
  event: 'event',
};

export const ORDER_DISPLAY_TYPE = {
  grid: 'grid',
  list: 'list',
};

export const AUTH_PROVIDERS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const AUTH_TYPE = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
};

export const VALIDATION_REG_EXP = {
  // eslint-disable-next-line max-len
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberEmail: /^[\d()]+$/,
  rePhone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
};

export const ERROR = {
  // eslint-disable-next-line max-len
  EMAIL_ALREADY_EXIST: 'The email address you have entered is already registered.',
  WRONG_AUTH_DATA: 'Wrong auth data',
  ALREADY_LOGGED: 'You are already logged in',
  VERIFY_FAILED: 'Verification failed.',
  EMAIL_ALREADY_VERIFY: 'Email already verified.',
  USER_NOT_FOUND: 'User not found.',
  RESET_PSWD_KEY_EXPIRED: 'Reset password key expired.',
  RESET_PSWD_FAILED: 'Reset password failed.',
  CANCELED: 'ERR_CANCELED',
  WRONG_PROMOCODE: 'Wrong promo code',
  GOOGLE_TOKEN: 'Google recaptcha token required',
  ROBOT: 'Google recaptcha token is invalid or you look like a robot',
  WRONG_EMAIL: 'A valid email address is required',
  SHORT_PASSWORD: 'The password is too short',
  PASS_DONT_MATCH: 'PASSWORDS DON’T MATCH',
  EMAIL_EXIST: 'The email address you have entered is already registered',
  ENTER_EMAIL: 'Enter your Email',
  ENTER_FIRST_NAME: 'Enter your first name',
  ENTER_LAST_NAME: 'Enter your last name',
  WRONG_PASSWORD: 'Invalid email or password. Please try again.',
  SOMETHING_WRONG: 'ERRORS.somethingWrong',
  NO_API_KEY: 'No chanel or api key found',
};

export const ERROR_CODE = {
  SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  THROTTLING: 429,
};

export const TIME_ZONES = [
  {
    name: 'EDT',
    value: 'America/Toronto',
  },
  {
    name: 'Bogota',
    value: 'America/Bogota',
  },
  {
    name: 'Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    name: 'Sao Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    name: 'Madrid',
    value: 'Europe/Madrid',
  },
  {
    name: 'Paris',
    value: 'Europe/Paris',
  },
  {
    name: 'Rome',
    value: 'Europe/Rome',
  },
  {
    name: 'Prague',
    value: 'Europe/Prague',
  },
  {
    name: 'Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    name: 'Berlin',
    value: 'Europe/Berlin',
  },
  {
    name: 'Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    name: 'Zurich',
    value: 'Europe/Zurich',
  },
  {
    name: 'Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    name: 'Moscow',
    value: 'Europe/Moscow',
  },
  {
    name: 'Johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    name: 'Cairo',
    value: 'Africa/Cairo',
  },
  {
    name: 'Dubai',
    value: 'Asia/Dubai',
  },
  {
    name: 'Colombo',
    value: 'Asia/Colombo',
  },
  {
    name: 'Istanbul',
    value: 'Asia/Istanbul',
  },
  {
    name: 'Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    name: 'Taipei',
    value: 'Asia/Taipei',
  },
  {
    name: 'Singapore',
    value: 'Asia/Singapore',
  },
  {
    name: 'Seoul',
    value: 'Asia/Seoul',
  },
  {
    name: 'Hong Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    name: 'Sydney',
    value: 'Australia/Sydney',
  },
];

export const TIME_ZONE_FROM_SHORT = {
  'PST': 'America/Los_Angeles',
  'PDT': 'America/Santa_Isabel',
  'MDT': 'America/Chihuahua',
  'NZDT': 'Pacific/Auckland',
  'AEST': 'Australia/Melbourne',
  'ACST': 'Australia/Darwin',
  'AWST': 'Australia/Perth',
  'EEST': 'Africa/Tripoli',
  'CEST': 'Africa/Ceuta',
  'WEST': 'Europe/Lisbon',
  'GMT/UTC': 'Europe/London',
  'ADT': 'America/Glace_Bay',
  'EDT': 'America/Detroit',
  'EST': 'America/Havana',
  'CDT': 'America/Chicago',
};

export const LOCAL_TIME_DISPLAYING = true;

export const PACKAGE_TYPES = {
  SINGLE: 'single',
  CATEGORY: 'category',
};

export const STRIPE_API_VERSION = '2022-08-01';


export const USER_CAPABILITY = {
  watch: 'watch',
  download: 'download',
  clipping: 'clipping',
  download_clip: 'download_clip',
};
export const USER_FEATURES = {
  CLIPS: 'clips',
  DOWNLOADS: 'downloads',
};

export const PLAYER_ACTIONS = {
  rewind: 'rewind',
  play: 'play',
  pause: 'pause',
  stop: 'stop',
  interface: 'interface',
  getTime: 'get_time',
  totalTime: 'get_total_time',
  playPart: 'play_part',
  firstFrame: 'firstFrame',
  first_frame: 'first_frame',
  ready: 'ready',
  complete: 'complete',
  error: 'error',
  seek: 'seek',
};

export const SLIDES_TYPES = {
  main: '',
  category: 'event_category',
  participant: 'participant',
  group: 'participant_group',
};

export const FAVORITE_TYPES = {
  participant: 'participant',
  group: 'participant_group',
  category: 'event_category',
  event: 'favorite_event',
};
export const FAVORITE_SUBJECT = {
  participant: 'team',
  participant_group: 'school',
  event_category: 'sport',
};

export const TOAST_TYPES = {
  success: 'success',
  error: 'error',
  info: 'info',
};

export const PARTICIPANT_PROP_NAMES = {
  GENDER: 'gender',
  SPORT: 'sport',
  CITY: 'city',
  STATE: 'state',
};

export const CLIP_STATUSES = {
  processing: 'processing',
  success: 'success',
  error: 'error',
};

export const CUSTOM_FORM_ALIASES = {
  USER: 'user',
};

export const GENDERS = {
  man: 'male',
  woman: 'female',
  notIdentify: 'not identified',
};
export const SHORT_GENDERS_NAMES = {
  [GENDERS.man]: 'M',
  [GENDERS.woman]: 'W',
};
export const LONG_GENDERS_NAMES = {
  [GENDERS.man]: 'Men',
  [GENDERS.woman]: 'Women',
};
export const API_METHODS = {
  GET: 'get',
  DELETE: 'delete',
  POST: 'post',
};
export const LOCATIONS = {
  LOCALHOST: 'localhost',
};

export const SUBSCRIPTION_PERIODS = {
  ANNUAL: 'annual',
  MONTH: 'month',
  MONTHLY: 'monthly',
};
