// ---------- AUTH
export const GET_CHANNEL_KEY = 'GET_CHANNEL_KEY';
export const GET_API_KEY = 'GET_API_KEY';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_USER_ACCESS = 'GET_USER_ACCESS';
export const DO_LOGIN = 'DO_LOGIN';
export const DO_REGISTRATION = 'DO_REGISTRATION';
export const DO_LOGOUT = 'DO_LOGOUT';
export const DO_VERIFY = 'DO_VERIFY';
export const DO_PASSWORD_RESET_REQUEST = 'DO_PASSWORD_RESET_REQUEST';
export const DO_PASSWORD_RESET = 'DO_PASSWORD_RESET';
export const DO_UPDATE_PROFILE = 'DO_UPDATE_PROFILE';
export const ADD_SESSION_BY_CODE = 'ADD_SESSION_BY_CODE';
export const SOCIAL_AUTH = 'SOCIAL_AUTH';

// ---------- CHANNEL

export const GET_CHANNEL_SETTINGS = 'GET_CHANNEL_SETTINGS';
export const GET_CHANNEL_MENU = 'GET_CHANNEL_MENU';
export const GET_CHANNEL_SLIDES = 'GET_CHANNEL_SLIDES';
export const GET_CHANNEL_PAGES = 'GET_CHANNEL_PAGES';
export const GET_CHANNEL_PAGE = 'GET_CHANNEL_PAGE';
export const GET_COLOR_SETTINGS = 'GET_COLOR_SETTINGS';

// ---------- CUSTOM FORM
export const GET_CUSTOM_FORM_DATA = 'GET_CUSTOM_FORM_DATA';

// ---------- EVENT
export const GET_EVENTS_LIST = 'GET_EVENTS_LIST';
export const GET_HOME_PAGE_EVENTS = 'GET_HOME_PAGE_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const DOWNLOAD_EVENT = 'DOWNLOAD_EVENT';
export const GET_EVENT_CATEGORIES = 'GET_EVENT_CATEGORIES';

// ---------- FAQ
export const GET_FAQ = 'GET_FAQ';

// ---------- GEO
export const GET_GEO_DATA = 'GET_GEO_DATA';

// ---------- PARTICIPANT
export const GET_ALL_PARTICIPANTS = 'GET_ALL_PARTICIPANTS';
export const GET_PARTICIPANT_GROUP_LIST = 'GET_PARTICIPANT_GROUP_LIST';
export const GET_PARTICIPANTS_PROPS = 'GET_PARTICIPANTS_PROPS';

// ---------- PAYMENT
export const PAYMENT_BUY = 'PAYMENT_BUY';
export const GET_PACKAGES_CACHED = 'GET_PACKAGES_CACHED';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_SUBSCRIPTIONS_CACHED = 'GET_SUBSCRIPTIONS_CACHED';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_PACKAGE = 'GET_PACKAGE';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const APPLY_COUPON = 'APPLY_COUPON';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const GET_INFO_FOR_ORDER_BY_TYPE = 'GET_INFO_FOR_ORDER_BY_TYPE';
export const ORDER_PAY = 'ORDER_PAY';
export const GET_STRIPE_PORTAL = 'GET_STRIPE_PORTAL';
export const GET_USER_CARDS = 'GET_USER_CARDS';
export const START_TRIAL = 'START_TRIAL';
export const GET_CLIENT_SECRET_STRIPE = 'GET_CLIENT_SECRET_STRIPE';

// ----------- VOD MEDIA
export const GET_VOD_ITEMS = 'GET_VOD_ITEMS';
export const GET_VOD_ITEM = 'GET_VOD_ITEM';
export const GET_VOD_CATEGORIES = 'GET_VOD_CATEGORIES';
export const SEARCH_IN_VOD = 'SEARCH_IN_VOD';

// ---------- CLIPS
export const SEND_CLIP = 'SEND_CLIP';
export const GET_ALL_CLIPS_FOR_ID = 'GET_ALL_CLIPS_FOR_ID';
export const DOWNLOAD_CLIP = 'DOWNLOAD_CLIP';
export const DELETE_CLIP = 'DELETE_CLIP';
export const GET_ALL_CLIPS = 'GET_ALL_CLIPS';
export const GET_ALL_EVENTS_WITH_CLIPS = 'GET_ALL_EVENTS_WITH_CLIPS';
export const GET_SHAREDCLIP = 'GET_SHAREDCLIP';

// ---------- FAVORITES
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const DELETE_FAVORITE = 'DELETE_FAVORITE';
export const GET_FAVORITE = 'GET_FAVORITE';
export const GET_FAVORITE_EVENTS = 'GET_FAVORITE_EVENTS';

// ----------- SETTINGS
export const ADD_POPUP = 'ADD_POPUP';
